import React, {useEffect, useState} from 'react';

import {
    AppBar,
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    Divider,
    Grid,
    LinearProgress,
    List,
    MenuItem as MenuItemS,
    Select,
    Tab,
    Table,
    TableCell,
    TableRow,
    Tabs,
    Typography
} from '@material-ui/core';


import {CalendarToday, CheckBox, Close, InfoOutlined, Menu} from '@material-ui/icons';
import {Legend, Pie, PieChart, Tooltip} from 'recharts';
import uniq from "lodash/uniq";
import {MuiTabs} from "./MuiTabs";
import {dishFilter} from "../Service/DishFilter";
import {CustomerMap} from "./CustomerMap";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import {NutritionalSetBlock} from "./NutritionalValuesBlocks";
import {AllergensBlock} from "./Allergens";
import {DishImage} from "./DishImages";
import {FOOD_CHARACTERISTICS_NAMES} from "../Model/Dish";
import Chip from "@material-ui/core/Chip";

function TabPanel(props) {

    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );

}


const IngredientBlock = ({ingredients}) => {

    const data = ingredients.filter(i => i.public);

    return <CardContent>
        <List>
            {
                data.map(i => {

                        const commercialName = i?.ingredient?.commercialName;
                        const name = i?.ingredient?.name;

                        return <li>
                            {commercialName ?? name}
                        </li>
                    }
                )
            }
        </List>
    </CardContent>
};

export const DishInfo = ({data, dish, customer, APISERVICE}) => {

    return <CardContent>
        <IngredientBlock ingredients={dish.fullIngredients}/>
    </CardContent>
};

export const DishAlergens = ({data, dish, customer, APISERVICE}) => {

    const allergens = uniq([].concat(dish.allergens).concat(dish.ingredientChildAllergens).filter(i => i));

    return <CardContent>
        <AllergensBlock allergens={allergens}/>
    </CardContent>
};

export const DishNutritionalValues = ({data, dish, customer, APISERVICE}) => {


    const rationWeight = (dish.recipeWeight ?? 1) / (dish.rationCount ?? 1);

    const pCent = rationWeight / 100;

    const [mult, setMult] = useState(1);

    const nData = data ? [
        {'name': 'Grasas', value: Math.ceil(data.fat * mult), fill: '#ff5555'},
        {'name': 'Proteínas', value: Math.ceil(data.proteins * mult ), fill: '#5555FF'},
        {'name': 'Hidratos de carbono', value: Math.ceil(data.carbohydrates * mult), fill: '#2AA92A'}
    ] : null;


    const renderLabel = (r) => {
        return `${r.value}g`
    };



    return <CardContent>

        <CardContent>
            <Select value={mult} onChange={(e) => setMult(e.target.value)}>
                <MenuItemS value={1}>
                    Por 100g
                </MenuItemS>
                <MenuItemS value={pCent}>
                    Por ración {rationWeight}g
                </MenuItemS>
            </Select>
        </CardContent>

        {customer.showFullNutritionalValues && <NutritionalSetBlock record={dish} mult={mult}/>}
        <div className="tcenter">
            <PieChart width={290} height={290}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={nData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label={renderLabel}
                />
                <Tooltip/>
                <Legend/>
            </PieChart>
        </div>
    </CardContent>
};


const DishDialog = ({dish, APISERVICE, customer, onClose}) => {

    const url = `/public-api/dishes/${customer.ownerSlug}/${dish.id}`;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        APISERVICE.fetch(url, (dish) => {
            setData(dish);
            setLoading(false);
        })

    }, []);


    const pvp = dish?.pvp?.amount ? `${(dish.pvp.amount /100).toFixed(2)}€ ` : '';

    let tabs = [];
    let panels = [];


    if (data) {


        if (data.dishImages.length) {
            tabs.push(<Tab label={"Presentación"}/>);
        }

        tabs.push(<Tab label={"Alérgenos"}/>);
        tabs.push(<Tab label={"Valores nutricionales"}/>);
        tabs.push(<Tab label={"Elaboración"}/>);

        if (data.dishImages.length) {
            panels.push(<DishImage data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);
        }

        panels.push(<DishAlergens data={data} customer={customer} dish={dish} APISERVICE={APISERVICE}/>);
        panels.push(<DishNutritionalValues data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);
        panels.push(<DishInfo data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);


    }


    return <Dialog open={true} maxWidth={"xl"} scroll={"body"}>
        <CardContent>
            <Grid container>
                <Grid item xs={7}>
                    <h4>{dish.name}</h4>
                    <h6>{dish.foodCharacteristics.map(
                        m => <>
                            <Chip
                                label={FOOD_CHARACTERISTICS_NAMES[m]}
                                icon={<CheckBox />}
                                color={"success"}
                            />
                        </>
                    )}</h6>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant={"outlined"}
                        startIcon={<Close />}
                        style={{'float' : 'right' }}
                        onClick={() => onClose()}>Cerrar
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </CardContent>
        <Collapse in={loading} timeout={800}>
            {loading && <LinearProgress/>}
        </Collapse>
        <Collapse in={!loading} timeout={800}>
            <div className="dialog-content">
                {data ? <MuiTabs
                    var
                    tabs={tabs}
                    panels={panels}
                /> : <LinearProgress/>
                }
            </div>
            <div>
                <CardContent>
                    <h5 className={"text-right"}>
                        <b>{pvp}</b>
                    </h5>
                    <p className={"text-right"}>
                        {dish.pvpDescription ?? ''}
                    </p>

                </CardContent>
            </div>
        </Collapse>
    </Dialog>;
};

const DishRow = ({dish, customer, APISERVICE, type}) => {


    const [showDialog, setShowDialog] = useState(false);

    const allergens = uniq([].concat(dish.allergens).concat(dish.ingredientChildAllergens).filter(i => i));

    const pvp = dish?.pvp?.amount;

    const name = dish?.commercialName ?? dish?.name;

    return (dish && !dish.outOfService) ? <TableRow>

        <TableCell onClick={() => setShowDialog(true)}>
            <h6 aria-label={name}><InfoOutlined className="info-icon"/> <b>{name}</b></h6>
            {dish?.menusection?.name}
            <AllergensBlock allergens={allergens}/>
        </TableCell>
        {type === 'STANDARD' ?
            <TableCell className={"text-right"}>
            <span className={"bold dish-price"}>
                {pvp ? (pvp / 100).toFixed(2) : ''} {pvp ? "€" : ''}
            </span>
            </TableCell> : null}
        {showDialog &&
        <DishDialog dish={dish} customer={customer} APISERVICE={APISERVICE} onClose={() => setShowDialog(false)}/>}
    </TableRow> : null
};

export const MenuItem = ({menu, customer, APISERVICE, filters, type}) => {


    const url = `/public-api/menu-dishes/${customer.ownerSlug}/${menu.id}`;

    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        APISERVICE.fetch(url, (dishes) => {
            setDishes(dishes);
            setLoading(false)
        })

    }, []);


    const filteredDishes = dishFilter(dishes, filters);


    return <>
        <Collapse in={loading}>
            {loading && <LinearProgress/>}
        </Collapse>
        <Collapse
            in={!loading}
            timeout={"auto"}
        >
            <Card elevation={1}>
                <CardContent>
                    <br/>
                    <h3 aria-label={menu.name.toUpperCase()}><b><i>{menu.name.toUpperCase()}</i></b></h3>
                    <br/>
                    {filteredDishes['menuSection'] &&
                    Object.keys(filteredDishes['menuSection']).map(
                        ms => {

                            const currentDishes = Array.from(filteredDishes['menuSection'][ms]).filter(i => i);

                            return <Box sx={{'mb': 2}}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <h5><b><i>{ms.toUpperCase()}</i></b></h5><br/>
                                        {(currentDishes && currentDishes.length) ? <Table size={"small"}>
                                            {currentDishes ? currentDishes.map(
                                                d => <DishRow
                                                    dish={d}
                                                    type={type}
                                                    customer={customer}
                                                    APISERVICE={APISERVICE}/>
                                            ) : 'Aún No hay platos en esté menú'}
                                        </Table> : null
                                        }
                                    </CardContent>
                                </Card>
                            </Box>
                        }
                    )
                    }

                    <Table size={"small"}>
                        {
                            Object.keys(filteredDishes).map(
                                ms => {

                                    if (ms === 'menuSection') {
                                        return null
                                    }

                                    const currentDish = filteredDishes[ms];

                                    return <>
                                        {currentDish ? <DishRow
                                            dish={currentDish}
                                            customer={customer}
                                            type={type}
                                            APISERVICE={APISERVICE}/> : null}
                                    </>
                                }
                            )
                        }
                    </Table>


                    <br/>
                    <CardContent>
                        <p>
                            <i><b>{menu.description}</b></i>
                        </p>
                        <p>
                            <i>
                                <b>
                                    {menu?.pvp ? (menu?.pvp / 100).toFixed(2) : ''} {menu?.pvp ? "€" : ''}
                                </b>
                            </i>
                        </p>
                    </CardContent>
                </CardContent>
            </Card>
        </Collapse>
    </>
};

const MenuView =
    (
        {
            daily,
            standard,
            event,
            customer,
            APISERVICE,
            filters
        }
    ) => {

        const [value, setValue] = useState(0);

        const handleChange = (e, value) => {
            setValue(value);
        };

        const menuItems = standard.menus.filter(m => m.webPublic);
        const dailyItems = daily.menus.filter(m => m.webPublic);
        const eventItems = event.menus.filter(m => m.webPublic);

        let count = 0;


        return <div className={"full relative"}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    aria-label="Menús">
                    {dailyItems.length ? <Tab label={<>
                        <Menu/>
                        Menús
                    </>} color={"secondary"}/> : null}
                    {eventItems.length ? <Tab label={<>
                        <CalendarToday/> Eventos
                    </>} color={"secondary"}/> : null}
                    {menuItems.map(m => <Tab label={m.name}/>)}
                </Tabs>
            </AppBar>
            <div className="container py-4" id={"menu-tabs"}>

                {dailyItems.length ? <TabPanel value={value} index={count}>


                    {dailyItems.map(
                        (m, loop) => <>
                            <Paper>
                                <MenuItem menu={m} APISERVICE={APISERVICE} customer={customer} filters={filters} type={"DAILY"}/>
                            </Paper>
                            <br/><br/>
                        </>)
                    }
                </TabPanel> : null}

                {(dailyItems.length && count++) ? '' : ''}

                {eventItems.length ? <TabPanel value={value} index={count}>
                    {eventItems.map(
                        (m, loop) => <Paper>
                            <MenuItem menu={m} APISERVICE={APISERVICE} customer={customer} filters={filters} type={"EVENT"}/>
                            <br/><br/>
                        </Paper>)
                    }
                </TabPanel> : null}

                {(eventItems.length && count++) ? '' : ''}

                {menuItems.map(
                    (m, loop) => <TabPanel value={value} index={loop + count} id={`menu-${m.id}`}>
                        <MenuItem menu={m} APISERVICE={APISERVICE} customer={customer} filters={filters} type={"STANDARD"}/>
                    </TabPanel>)
                }
            </div>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    aria-label="Menús">
                    {dailyItems.length ? <Tab href="#menu-link" label={<>
                        <Menu/>
                        Menús
                    </>} color={"secondary"}/> : null}
                    {eventItems.length ? <Tab href="#menu-link" label={<>
                        <CalendarToday/> Eventos
                    </>} color={"secondary"}/> : null}
                    {menuItems.map(m => <Tab href="#menu-link" label={m.name}/>)}
                </Tabs>
            </AppBar>
            {(customer.ownerLatitude != null && customer.ownerLongitude !=null ) && <CustomerMap customer={customer} APISERVICE={APISERVICE}/>}
        </div>
    };

export const CustomerMenus = ({customer, APISERVICE, filters}) => {


    const url = `/public-api/menus/${customer.ownerSlug}/STANDARD`;
    const dailyUrl = `/public-api/menus/${customer.ownerSlug}/DAILY`;
    const eventUrl = `/public-api/menus/${customer.ownerSlug}/EVENT`;

    const [standard, setStandard] = useState(null);
    const [daily, setDaily] = useState(null);
    const [event, setEvent] = useState(null);

    useEffect(() => {

        APISERVICE.fetch(url,
            (data) => {
                setStandard(data);
            }
        )

    }, []);

    useEffect(() => {

        APISERVICE.fetch(dailyUrl,
            (data) => {
                setDaily(data);
            }
        )

    }, []);

    useEffect(() => {

        APISERVICE.fetch(eventUrl,
            (data) => {
                setEvent(data);
            }
        )

    }, []);

    return (daily && standard && event) ? <MenuView
        standard={standard}
        daily={daily}
        event={event}
        APISERVICE={APISERVICE}
        customer={customer}
        filters={filters}
    /> : <LinearProgress/>

};
